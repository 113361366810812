var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("div", { staticClass: "mb-5 ml-5" }, [
                _vm._v("\n        Email Suppression Code:\n        "),
                _c(
                  "div",
                  { staticClass: "mt-2" },
                  [
                    _c("v-chip", { attrs: { color: "success" } }, [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.$route.query.sc))
                      ])
                    ])
                  ],
                  1
                )
              ]),
              _c("v-card-subtitle", [
                _vm._v(
                  "\n        Select field for email suppression and import file.\n      "
                )
              ]),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "ml-3 mr-3 mb-5" },
                        [
                          _c("v-card-title", [_vm._v(" Column Setup ")]),
                          _c(
                            "v-row",
                            [
                              _c("v-switch", {
                                staticClass: "ml-9",
                                attrs: {
                                  label: "First Row Contains Column Names"
                                },
                                model: {
                                  value: _vm.isColumnNames,
                                  callback: function($$v) {
                                    _vm.isColumnNames = $$v
                                  },
                                  expression: "isColumnNames"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "ml-2 mr-2",
                              staticStyle: { "justify-content": "left" }
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.computedHeaders,
                                      "return-object": "",
                                      dense: "",
                                      outlined: "",
                                      label: "Email Address",
                                      color: "primary"
                                    },
                                    model: {
                                      value: _vm.emailAddress,
                                      callback: function($$v) {
                                        _vm.emailAddress = $$v
                                      },
                                      expression: "emailAddress"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "ml-3 mr-3" },
                [
                  _c("v-card-title", [
                    _vm._v("\n          File Preview\n        ")
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "ml-5" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-data-table", {
                            attrs: {
                              headers: _vm.computedHeaders,
                              items: _vm.computedRows,
                              "disable-pagination": "",
                              "hide-default-footer": "",
                              "disable-filtering": ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-col", [
                    _vm.importError !== ""
                      ? _c(
                          "small",
                          {
                            staticClass: "ml-10 mb-5",
                            staticStyle: { color: "red" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.importError) +
                                "\n          "
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "mb-5" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "success",
                            loading: _vm.importLoading
                          },
                          on: {
                            click: function($event) {
                              return _vm.validateImport()
                            }
                          }
                        },
                        [_vm._v("\n            Import file\n          ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "tertiary" },
                          on: {
                            click: function($event) {
                              return _vm.cancel()
                            }
                          }
                        },
                        [_vm._v("\n            cancel\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }