<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div
          class="mb-5 ml-5"
        >
          Email Suppression Code:
          <div class="mt-2">
            <v-chip color="success">
              <span class="font-weight-bold">{{ $route.query.sc }}</span>
            </v-chip>
          </div>
        </div>
        <v-card-subtitle>
          Select field for email suppression and import file.
        </v-card-subtitle>
        <v-row>
          <v-col cols="12">
            <v-card class="ml-3 mr-3 mb-5">
              <v-card-title> Column Setup </v-card-title>
              <v-row>
                <v-switch
                  v-model="isColumnNames"
                  label="First Row Contains Column Names"
                  class="ml-9"
                />
              </v-row>
              <v-row
                class="ml-2 mr-2"
                style="justify-content: left"
              >
                <v-col cols="3">
                  <v-select
                    v-model="emailAddress"
                    :items="computedHeaders"
                    return-object
                    dense
                    outlined
                    label="Email Address"
                    color="primary"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-card class="ml-3 mr-3">
          <v-card-title>
            File Preview
          </v-card-title>
          <v-row class="ml-5">
            <v-col cols="auto">
              <v-data-table
                :headers="computedHeaders"
                :items="computedRows"
                disable-pagination
                hide-default-footer
                disable-filtering
              />
            </v-col>
          </v-row>
          <v-col>
            <small
              v-if="importError !== ''"
              class="ml-10 mb-5"
              style="color: red"
            >
              {{ importError }}
            </small>
          </v-col>
          <v-card-actions class="mb-5">
            <v-btn
              color="success"
              :loading="importLoading"
              @click="validateImport()"
            >
              Import file
            </v-btn>
            <v-btn
              color="tertiary"
              @click="cancel()"
            >
              cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { nowwService } from '@/shared/services'
import { mapGetters } from 'vuex'
export default {
  name: 'ImportEmailSuppression',

  props: {
    stepVal: {
      type: Number,
      default: 2
    },
    row0: {
      type: String,
      default: ''
    },
    fileArray: {
      type: Array,
      default: () => ([])
    },
    fileData: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      results: [],
      valid: false,
      isColumnNames: false,
      selectedItem: '',
      emailAddressIndex: '',
      importError: '',
      emailAddress: '',
      resultsLoading: false,
      importLoading: false,
      headerArray: [],
      row0Array: [],
      fieldNames: [],
      previewHeaders: [],
      headerValue: []
    }
  },

  computed: {
    ...mapGetters(['viewAsCustomer', 'simulatedCustomerCrmId']),
    ...mapGetters('auth', [
      'isMMSRole',
      'getTenantId',
      'getTenantCrmId'
    ]),
    showViewAsCustomer () {
      return this.$store.getters['auth/isMMSRole']()
    },
    isLoading () {
      return this.loadingCount > 0
    },
    computedHeaders () {
      var headerObject = []
      var headers = []
      if (this.isColumnNames) {
        for (var i = 0; i < this.row0Array.length; i++) {
          headerObject = {
            text: this.row0Array[i],
            align: 'left',
            sortable: false,
            value: 'col00' + i
          }
          headers.push(headerObject)
        }
      } else {
        for (var j = 0; j < this.row0Array.length; j++) {
          var index = j + 1
          headerObject = {
            text: 'Col 00' + index,
            align: 'left',
            sortable: false,
            value: 'col00' + j
          }
          headers.push(headerObject)
        }
      }
      return headers
    },
    computedRows () {
      var i = !this.isColumnNames ? 0 : 1
      var headerValue = []
      var splitArray = []
      var stringSplit = []

      // Get amount of headers and assign a dynamic value
      for (var j = 0; j < this.row0Array.length; j++) {
        var columnValue = 'col00' + j
        headerValue.push(columnValue)
      }
      // split file values into strings array - change to 9 to display only 9 entries
      for (i; i <= 9; i++) {
        if (!this.fileArray[i] || this.fileArray[i].trim() === '') {
          continue
        }
        splitArray = this.fileArray[i].split(/[,\t]/)
        stringSplit.push(splitArray)
      }

      // Assign new object from string arrays
      var rowsResult = stringSplit.map(value => Object.assign({}, ...headerValue.map((key, l) =>
        ({ [key]: value[l] }))))

      return rowsResult
    }
  },

  watch: {
    computedHeaders: {
      handler: function (newVal) {
        this.headerArray = newVal
      }
    }
  },

  created () {
    this.getRow0Array(this.row0)
    this.validateCSV()
    this.scrollTop()
    this.getHeaders()
  },

  methods: {
    scrollTop () {
      setTimeout(() => {
        window.scrollTo({
          top: 0
        })
      }, 0)
    },
    clearErrors () {
      this.importError = ''
    },
    getRow0Array (value) {
      this.row0Array = value.split(',')
    },
    cancel () {
      this.$emit('cancelStep', this.stepVal)
    },
    validateCSV () {
      var reValid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/
      var reValue = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g
      // Return NULL if input string is not well formed CSV string.
      if (!reValid.test(this.fileData)) return null
      var a = [] // Initialize array to receive values.
      this.fileData.replace(reValue, // "Walk" the string using replace with callback.
        function (m0, m1, m2, m3) {
          // Remove backslash from \' in single quoted values.
          if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"))
          // Remove backslash from \" in double quoted values.
          else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'))
          else if (m3 !== undefined) a.push(m3)
          return '' // Return empty string.
        })
      // Handle special case of empty last value.
      if (/,\s*$/.test(this.fileData)) a.push('')
      return a
    },
    getHeaders () {
      for (var i = 0; i < this.row0Array.length; i++) {
        var columnValue = 'col00' + i
        this.headerValue.push(columnValue)
      }
      return this.headerValue
    },

    async validateImport () {
      var _this = this
      _this.clearErrors()
      // Map fields to proper value from uploaded file
      for (var m = 0; m < _this.computedHeaders.length; m++) {
        if (_this.computedHeaders[m].text === _this.emailAddress.text) {
          _this.emailAddressIndex = m.toString()
        }
      }

      if (_this.emailAddress === '') {
        _this.importError = 'Please select a column for the email address.'
        return
      }

      await nowwService.deleteEmailSuppressionRecords(_this.$route.query.sid)
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          }
        })
      await nowwService.emailSuppressionBulkInsertFromTable({
        suppressionId: _this.$route.query.sid,
        supType: 'suppression',
        dtFinal: _this.fileArray,
        emailAddressIndex: _this.emailAddressIndex
      })
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.results = resp
            _this.$emit('advanceFromStep', _this.stepVal)
          }
        })
        .finally(() => {
          _this.importLoading = false
        })
    }
  }
}
</script>
